// import { faGift } from "@jewlr/pro-light-svg-icons/faGift"
import {
  Box,
  // FontAwesomeIcon,
  Typography,
} from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"

import withCollection from "areas/collection/hoc/with-collection"
import { IS_JEWLR } from "helpers/application"
import { setProductsClickedOnHistory } from "helpers/collection"

import LooksSelector from "../looks-selector"

import ItemContainer from "./item-container"
import ProductDescription from "./product-description"
import ProductImage from "./product-image"
import ProductPrice from "./product-price"
import WishlistIcon from "./wishlist-icon"

const ImageWrapper = styled(Box).attrs({
  mb: 1,
})`
  display: block;
`

export const Badge = styled(Typography).attrs((props) => ({
  as: "span",
  color: "black",
  left: "20px",
  top: "5px",
  ...props,
}))`
  align-items: center;
  border-radius: 10px;
  display: inline-flex;
  justify-content: center;
  font-size: 12px;
  line-height: 14px;
  padding: 2px 8px;
  position: absolute;
  z-index: 1;
`

const LooksSelectorWrapper = styled(Box).attrs((props) => ({
  height: "33px",
  ...props.theme.collectionLooksWrapper,
}))``

const StyledItemContainer = styled(ItemContainer)`
  margin-top: 4px;
  ${(props) =>
    !props.inCollection &&
    `
    &:first-of-type {
      margin-left: 4px;
    }
  `}
`

const InternalCollectionProduct = ({
  confirmWishlist,
  containerStyle,
  inCollection,
  index,
  newPageStartIndex,
  newPageFocusRef,
  product,
  setProductParams,
}) => {
  const [selectedLook, setSelectedLook] = useState(setDefaultLook(product))
  const [firstImageLoaded, setFirstImageLoaded] = useState(true)
  const ref = useRef()

  useEffect(() => {
    // Update selected metal when default_look is changed
    setSelectedLook(setDefaultLook(product))
  }, [product])

  const setClickedOnHistory = () => {
    setProductsClickedOnHistory(product.rm_style_code || product.style_code, {
      cidx: index + 1,
      cpg: product.pageIndex || 1,
      csc: product.style_code,
    })
  }

  const selectedProductLook = product.looks[selectedLook]

  if (!selectedProductLook) {
    // Prevents selectedLook from being undefined and breaking the component on reload of products.
    // Gives useEffect a chance to update.
    return null
  }
  const badge = product.badges && product.badges[0]

  return (
    <StyledItemContainer
      {...containerStyle}
      inCollection={inCollection}
      ref={ref}
    >
      <Link
        aria-label={product.name}
        data-cy={`collection-product-link-${product.style_code}`}
        data-lc="collection-product"
        onClick={setClickedOnHistory}
        ref={index === newPageStartIndex ? newPageFocusRef : undefined}
        to={selectedProductLook.product_url}
      >
        <ImageWrapper>
          <ProductImage
            alt={product.name}
            firstImageLoaded={firstImageLoaded}
            images={selectedProductLook.images}
            index={index}
            setFirstImageLoaded={(value) => setFirstImageLoaded(value)}
            styleCode={product.style_code}
            type={product.product_type}
          />
        </ImageWrapper>
      </Link>

      {confirmWishlist && IS_JEWLR() && (
        <WishlistIcon
          {...{
            confirmWishlist,
            product,
            selectedProductLook,
            setProductParams,
          }}
        />
      )}
      {badge && IS_JEWLR() && (
        <Badge bg={badge.color} color={badge.label_color} {...badge.style}>
          {badge.label}
        </Badge>
      )}
      <LooksSelectorWrapper>
        {Object.keys(product.looks).length > 1 && (
          <LooksSelector
            looks={product.looks}
            product={product}
            selectedLook={selectedLook}
            updateLook={(look) => setSelectedLook(look)}
          />
        )}
      </LooksSelectorWrapper>
      <Box>
        {/* {sl.config.show_availability_in_collection &&
          product.isAvailForChristmas && (
            <Typography color="#197603" fontSize={11} my="4px">
              <FontAwesomeIcon
                icon={faGift}
                style={{ verticalAlign: "text-top" }}
              />{" "}
              Christmas Delivery
            </Typography>
          )} */}
        <ProductDescription
          productName={product.name}
          productSubtitle={selectedProductLook.subtitle}
        />
        {selectedProductLook.selling && (
          <ProductPrice
            product={{
              ...selectedProductLook,

              style_code: product.style_code,
            }}
          />
        )}
        {product.hasDiamond && (
          <Typography as="span" fontSize={12} italic>
            {product.hasDiamond}
          </Typography>
        )}
      </Box>
    </StyledItemContainer>
  )
}

InternalCollectionProduct.propTypes = {
  confirmWishlist: PropTypes.func,
  containerStyle: PropTypes.object,
  detectScrollPageProduct: PropTypes.func,
  gridView: PropTypes.string,
  inCollection: PropTypes.bool,
  index: PropTypes.number,
  newPageFocusRef: PropTypes.object,
  newPageStartIndex: PropTypes.number,
  product: PropTypes.object,
  setProductParams: PropTypes.func,
}

const setDefaultLook = (product) => {
  return Object.prototype.hasOwnProperty.call(
    product.looks,
    product.default_look
  )
    ? product.default_look
    : Object.keys(product.looks)[0]
}

const CollectionProduct = withCollection(InternalCollectionProduct)

export default CollectionProduct
